<template>
    <a v-if="o" :href="o.url" target="_blank">{{ text }}</a>
</template>

<script>
import api from '@/services/api';

export default {
  props: [
    'id',
    'displayText',
  ],
  data() {
    return {
      o: null,
      loading: true,
      error: null,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        if (this.id === null) {
          this.o = null;
          this.loading = false;
          this.error = null;
          return;
        }
        this.loading = true;
        this.error = null;
        api.kernel.get(`/files/${this.id}`).then((data) => {
          this.o = data;
          this.loading = false;
          this.error = null;
        }, (error) => {
          this.loading = false;
          this.error = error;
        });
      },
    },
  },
  computed: {
    text() {
      if (this.displayText) {
        return this.displayText;
      }
      return `${this.o.name} (${this.size})`;
    },
    size() {
      if (this.o.size >= 1024 * 1024) {
        return `${Math.round(this.o.size / 1024 / 1024)} MB`;
      }
      if (this.o.size >= 1024) {
        return `${Math.round(this.o.size / 1024)} kB`;
      }
      return `${this.o.size} bytes`;
    },
  },
};

</script>

<style lang="scss">
input[type="file"] {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1px;
    height: 1px;
    opacity: 0;
}
</style>
